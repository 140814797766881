<template>
  <div>
    <h2> SHORTCUT MENU</h2>
    <v-row>
      <v-col
        v-for="(item,index) in shortcuts"
        cols="12"
        sm="6"
        md="2"
      >
        <router-link
          style="text-decoration: none;"
          :to="item.to"
        >
          <v-card style="min-height: 180px;background: #19466d">
            <v-card-text>
              <br><br><br>
              <v-icon
                class="text-center"
                style="font-size: 20px;width: 150px;;margin: 0 auto"
                small
              >
                <!--                {{ item.icon }}-->
              </v-icon>
              <h3
                class="text-center"
                style="color: #FFFFFF !important;"
              >
                {{ item.title }}
              </h3>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-card
          style="min-height: 180px;background: #f86c6b !important;"
          @click="deleteFilePattern"
        >
          <v-card-text>
            <br><br><br>
            <v-icon
              class="text-center"
              style="font-size: 20px;width: 150px;;margin: 0 auto"
              small
            >
              <!--                {{ item.icon }}-->
            </v-icon>
            <h3
              class="text-center"
              style="color: #FFFFFF !important;"
            >
              ลบไฟล์สั่งเพิ่มเติมหลัง
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <hr class="mt-5 mb-5">
    <h2> DASHBOARD</h2>
    <v-row>
      <v-col
        v-for="(item,index) in indexs"
        cols="12"
        sm="6"
        md="2"
      >
        <a
          style="text-decoration: none;"
          @click="openLink(item)"
        >
          <v-card style="min-height: 180px">
            <v-card-text>
              <h1 class="text-right">{{ report[item.count] }}</h1>
              <p>รวม {{ report[item.total] }} {{ item.unit }}</p>
              <p
                v-if="($store.state.permissions['all']) || ($store.state.permissions[item.status])"
              >{{
                $store.state.role_name && $store.state.role_name[item.status] ? $store.state.role_name[item.status] : '-'
              }}</p>
            </v-card-text>
          </v-card>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '../../../config'
import Swal from 'sweetalert2'
import instance_report from '../../services/report'
import instance_pattern from '../../services/pattern'

export default {
  components: { Swal },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    configs: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    shortcuts: [
      {
        icon: 'mdiPencil',
        title: 'รายการสั่งซื้อ  (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-report-booking-order' },
      },
      {
        icon: 'mdiPencil',
        title: 'ออกบิลขาย (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-orders' },
      },
      {
        icon: 'mdiPencil',
        title: 'เติมแพ็คเกจสินค้า (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-add-package' },
      },
      {
        icon: 'mdiPencil',
        title: 'เติมเครดิตเงินสด (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-add-credit-package' },
      },
      {
        icon: 'mdiPencil',
        title: 'จัดการวิธีการส่งสินค้า (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-transport-type' },
      },
      {
        icon: 'mdiPencil',
        title: 'จัดรายการเพิ่มสินค้า (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-product-online' },
      },
      {
        icon: 'mdiPencil',
        title: 'เพิ่มลายสินค้า  (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-pattern' },
      },
      {
        icon: 'mdiPencil',
        title: 'จัดการลูกค้า  (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-customers' },
      },
      {
        icon: 'mdiPencil',
        title: 'จัดการพนักงาน  (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-employee' },
      },
      {
        icon: 'mdiPencil',
        title: 'ข้อมูลส่วนตัว  (เมนูลัด)',
        backgroundColor: '#19466d',
        color: '#FFFFFF',
        to: { name: 'pages-profile' },
      },
    ],
    indexs: [
      {
        count: 'package_credits_wait_count',
        total: 'package_credits_wait',
        status: 'wait_check_package_credits_purchase_to_day',
        search: 'W',
        status_th: 'รอตรวจสอบการซื้อ',
        unit: 'บาท',
        to: { name: 'pages-credit-package' },
      },
      {
        count: 'package_wait_count',
        total: 'package_wait',
        status: 'wait_check_package_purchase_to_day',
        search: 'W',
        status_th: 'รอตรวจสอบการซื้อ',
        unit: 'บาท',
        to: { name: 'pages-report-add-package' },
      },
      {
        count: 'package_stale_count',
        total: 'package_stale',
        status: 'outstanding_order_package',
        search: 'W',
        status_th: 'มียอดค้างการซื้อ',
        unit: 'บาท',
        to: { name: 'pages-report-add-package' },
      },
      {
        count: 'order_expense_wait_transfer_count',
        total: 'order_expense_wait_transfer',
        status: 'bill_waiting_transferred_to_day',
        search: 'PT',
        status_th: 'บิลรอการแจ้งโอน',
        unit: 'บาท',
        to: { name: 'pages-report-booking-order' },
      },
      {
        count: 'order_expense_wait_confirm_count',
        total: 'order_expense_wait_confirm',
        status: 'bill_awaiting_verification_to_day',
        search: 'PC',
        status_th: 'บิลรอการตรวจสอบ',
        unit: 'บาท',
        to: { name: 'pages-report-booking-order' },
      },
      {
        count: 'order_expense_PP_count',
        total: 'order_expense_PP',
        status: 'bill_production_to_day',
        search: 'PP',
        status_th: 'บิลอยู่ระหว่างผลิต',
        unit: 'บาท',
        to: { name: 'pages-report-booking-order' },
      },
      {
        count: 'order_expense_PTP_count',
        total: 'order_expense_PTP',
        status: 'waiting_to_day',
        search: 'PTP',
        status_th: 'รอการจัดส่ง',
        unit: 'บาท',
        to: { name: 'pages-report-booking-order' },
      },
      {
        count: 'order_expense_F_count',
        total: 'order_expense_F',
        status: 'sen_to_day',
        search: 'F',
        status_th: 'ส่งแล้ววันนี้',
        unit: 'บาท',
        to: { name: 'pages-report-booking-order' },
      },
      {
        count: 'order_expense_Canceled_count',
        total: 'order_expense_Canceled',
        status: 'canceled_to_day',
        search: 'C',
        status_th: 'ยกเลิกวันนี้',
        unit: 'บาท',
        to: { name: 'pages-report-booking-order' },
      },
      {
        count: 'memberNew',
        total: 'memberNew',
        status: 'member_new_to_day',
        search: '',
        status_th: 'ตัวแทนสมัครใหม่',
        unit: 'คน',
        to: { name: 'pages-customers' },
      },
      {
        count: 'claim_waiting_count',
        total: 'claim_waiting_count',
        status: 'waiting_for_claim_review_to_day',
        search: 'W',
        status_th: 'รอการตรวจสอบเคลม',
        unit: 'รายการ',
        to: { name: 'pages-claim' },
      },
      {
        count: 'product_depleted',
        total: 'product_depleted',
        status: 'out_of_stock_to_day',
        search: '',
        status_th: 'รายการสินค้าหมด',
        unit: 'รายการ',
        to: { name: 'pages-product-online' },
      },

      // {
      //   count: 'product_depleted',
      //   total: 'product_depleted',
      //   status: 'out_of_stock_to_day',
      //   unit : ''
      // },
    ],
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
      },
      { text: 'ชื่อตั้งค่า', value: 'name' },
      { text: 'จำนวน/ค่า', value: 'value' },
      { text: 'สถานะ (เปิด/ปิด)', value: 'status' },
      { text: 'แก้ไขข้อมูล', value: 'actions' },
    ],
    report: {},
    editedIndex: -1,
    date: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    const date = new Date()
    const date2 = new Date()
    let day = date.getDate()
    const day2 = date.getDate()
    const last_day = this.lastday(date2.getFullYear(), date2.getMonth() - 1)
    const last_month = date.getMonth()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    if (day > last_day) {
      day = last_day
    }

    // this.date = `${year}-${String(month).padStart(2, '0')}-${day}`;

    this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`)
    this.date.push(`${year}-${String(month).padStart(2, '0')}-${day2}`)
    this.getReport()
  },

  methods: {
    lastday(y, m) {
      // Create a new Date object representing the last day of the specified month
      // By passing m + 1 as the month parameter and 0 as the day parameter, it represents the last day of the specified month
      return new Date(y, m + 1, 0).getDate()
    },
    deleteFilePattern() {
      // Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!'
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     Swal.fire(
      //       'Deleted!',
      //       'Your file has been deleted.',
      //       'success'
      //     )
      //   }
      // })
      // return false;
      Swal.fire({
        title: 'คุณแน่ใจต้องการลบไฟล์สั่งเพิ่มเติม !',
        text: 'ลบไฟล์สั่งเพิ่มเติม หลัง 30 วัน ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          // let loader = this.$loading.show({
          //   loader: 'dots',
          //   color: '#27ae60'
          // });
          if (willDelete.isConfirmed) {
            instance_pattern.deleteFile().then(res => {
              Swal.fire({
                icon: 'success',
                title: 'ลบไฟล์สั่งเพิ่มเติม หลัง 30 วัน สำเสร็จ',
                showConfirmButton: false,
                timer: 1500,
              })
            }).catch(err => {
              Swal.fire({
                icon: 'error',
                title: err.message,
                showConfirmButton: false,
                timer: 1500,
              })
            })

          //   axios.get('{{url('admin/deleteFilePattern')}}')
          // .then(response => {
          //     loader.hide()
          //
          //   })
          //     .catch(error => {
          //       loader.hide()
          //       this.swalAlert(error.message, 'error')
          //       console.log(error.message);
          //     });
          } else {
            Swal.fire('แน่ใจเเล้วสามารถยืนยันการ ลบไฟล์สั่งเพิ่มเติมหลัง 30 วัน ได้ทันที')

          //   swal("แน่ใจเเล้วสามารถยืนยันการ ลบไฟล์สั่งเพิ่มเติมหลัง 30 วัน ได้ทันที");
          //
          }
        })
    },
    editItem(item) {
      this.editedIndex = this.configs.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    getReport() {
      this.$store.state.isLoading = true
      const data = {
        search: '',
        date: this.date,
      }

      instance_report.getHomeReport(data).then(res => {
        this.report = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    openLink(item) {
      // date=2023-05-24&date=2023-06-24
      // 2023/05/24%20-%202023/06/24
      this.$router.push({ name: item.to.name, query: { search: item.search, status: item.status_th, date: this.date.toString() } })

      // router.push(})
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
